import { Attribute, ResourceConfig, ResourceProps } from 'angular-jsonapi';
import { ResourceWithOrganisation } from './resource-with-organisation.model';
import { ResourceTypeContact } from 'app/shared/resource/resource';

@ResourceConfig({
    type: ResourceTypeContact,
})
export class Contact extends ResourceWithOrganisation {
    @Attribute()
    name: string;

    @Attribute()
    alternateName: string;

    @Attribute()
    emails: string[];

    @Attribute()
    phones: string[];

    @Attribute()
    kind: ContactKind;

    @Attribute()
    position: string;

    constructor(props: Partial<ContactProps>) {
        super(props);
        if (props) {
            this.name = props.name;
            this.alternateName = props.alternateName;
            this.position = props.position;
            this.kind = props.kind;
            if (props.phone) {
                this.phones = [props.phone];
            }
            if (props.email) {
                this.emails = [props.email];
            }
        }
    }
}

export type ContactProps = Partial<ResourceProps> & {
    name: string;
    alternateName: string;
    kind: ContactKind;
    email: string;
    phone: string;
    position: string;
};

export enum ContactKind {
    CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
    TECHNICAL_SUPPORT = 'TECHNICAL_SUPPORT',
}

export class ContactKindName {
    static readonly [ContactKind.CUSTOMER_SUPPORT] = 'Customer Support';
    static readonly [ContactKind.TECHNICAL_SUPPORT] = 'Technical Support';
}

export const ContactKindList = Object.entries(ContactKind).map(([key, value]) => ({ key, value: ContactKindName[value] }));
