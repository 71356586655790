import { ResourceTypeChannelGroup } from 'app/shared/resource/resource';
import { ResourceConfig, Attribute, ResourceProps, Relationship, NestedAttribute, Wrapped, WrappedResource } from 'angular-jsonapi';
import { DialogueAgent, DialogueAgentProps } from './dialogue-agent.model';
import { ResourceWithOrganisation } from './resource-with-organisation.model';
import { ChannelKind } from 'app/modules/channel/channel.types';

export class MessagingCapabilities extends WrappedResource {
    @Wrapped()
    orderProcessingEnabled: boolean;
}

@ResourceConfig({
    type: ResourceTypeChannelGroup,
})
export class ChannelGroup extends ResourceWithOrganisation {
    @Attribute()
    name: string;

    @Attribute()
    description: string;

    @Attribute()
    defaultFallbackChannels: Map<ChannelKind, ChannelKind>;

    @NestedAttribute({ type: MessagingCapabilities })
    messagingCapabilities: MessagingCapabilities;

    @Relationship({ type: DialogueAgent })
    private defaultDialogueAgent: DialogueAgent;

    constructor(props: Partial<ChannelGroupProps>) {
        super(props);
        if (props) {
            this.name = props.name;
            this.description = props.description;
            this.defaultFallbackChannels = props.defaultFallbackChannels;
            this.messagingCapabilities = props.messagingCapabilities;

            if (props.defaultDialogueAgent) {
                this.defaultDialogueAgent = new DialogueAgent(props.defaultDialogueAgent);
            }
        }
    }

    DefaultDialogueAgent(): DialogueAgent {
        return this.defaultDialogueAgent;
    }

    withDefaultDialogueAgent(defaultDialogueAgent: DialogueAgent): void {
        this.defaultDialogueAgent = defaultDialogueAgent;
    }
}

export type ChannelGroupProps = Partial<ResourceProps> & {
    description: string;
    name: string;
    defaultDialogueAgent: Partial<DialogueAgentProps>;
    defaultFallbackChannels: any;
    messagingCapabilities: MessagingCapabilities;
};
