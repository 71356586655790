import { inject, Injectable } from '@angular/core';
import { GenericApiSvcConfig, ListResponse, Op } from 'angular-jsonapi';
import { AppState } from 'app/app.state';
import { JsonApiService } from 'app/core/service/json-api.service';
import { Organisation } from 'app/models/organisation.model';
import { FieldNameId, FieldNameKind, FieldNameOrganisation } from 'app/shared/jsonapi/fields';
import { RequestOptions } from 'app/shared/jsonapi/request-options';
import { Role } from 'app/shared/types/account.types';
import { InfoList } from 'app/shared/types/list.types';
import { BehaviorSubject, map, Observable, of, switchMap, tap } from 'rxjs';
import { AccountService } from '../../core/service/account.service';
import { Contact, ContactKind } from 'app/models/contact.model';

const ORGANISATION_ID = 'organisationId';
const ORGANISATION_NAME = 'organisation_name';
const LIMIT = 15;

@Injectable({
    providedIn: 'root',
})
export class OrganisationService {
    private appState = inject(AppState);
    private jsonApiService = inject(JsonApiService);
    private accountService = inject(AccountService);

    readonly infoListOrganisations$: Observable<InfoList>;

    readonly #infoListOrganisations = new BehaviorSubject<InfoList>({ totalCount: undefined, filter: '' });

    constructor() {
        this.infoListOrganisations$ = this.#infoListOrganisations.asObservable();
    }

    getOrganisations(page: number, limit = LIMIT): Observable<ListResponse<Organisation>> {
        const filterOrgsIds = this.#getOrganisationTargets();
        const query = new RequestOptions(page, limit);
        if (filterOrgsIds && filterOrgsIds.length) {
            query.addFilter({ key: [FieldNameId], value: filterOrgsIds.join(','), type: Op.In });
        }
        const opts = this.jsonApiService.generateQuery(query);
        return this.jsonApiService.list(Organisation, GenericApiSvcConfig.withSearchOpts(opts)).pipe(
            tap((res: ListResponse<Organisation>) => {
                if (res) {
                    this.setInfoListOrganisation(res.meta().get('pagination')?.totalCount);
                }
            })
        );
    }

    getOrganisationById(id: string): Observable<Organisation> {
        const query = new RequestOptions(0, 1);
        query.addFilter({ key: [FieldNameId], value: id, type: Op.In });
        const opts = this.jsonApiService.generateQuery(query);

        return this.jsonApiService.list(Organisation, GenericApiSvcConfig.withSearchOpts(opts)).pipe(map((res: ListResponse<Organisation>) => res.result()?.[0]));
    }

    getOrganisationId(): string {
        return localStorage.getItem(ORGANISATION_ID);
    }

    setActiveOrganisation(org: Organisation): void {
        this.appState.setActiveOrganisation(org);
        this.accountService.setRoleByOrg(org.id);
        localStorage.setItem(ORGANISATION_ID, org.id);
        localStorage.setItem(ORGANISATION_NAME, org.tradeName);
    }

    getActiveOrganisation(): Organisation {
        return this.appState.activeOrganisation();
    }

    loadActiveOrganisation(): Organisation {
        if (!this.getActiveOrganisation()) {
            if (this.getOrganisationId() && this.getOrganisationName()) {
                this.setActiveOrganisation(new Organisation({ id: this.getOrganisationId(), tradeName: this.getOrganisationName() }));
            }
        }
        return this.getActiveOrganisation();
    }

    checkActiveOrganisation(orgID?: string): Observable<boolean> {
        if (this.getInfoListOrganisation().totalCount === undefined) {
            return this.getOrganisations(0, 1000).pipe(
                switchMap((res: ListResponse<Organisation>, _index: number) => {
                    if (res?.meta().get('pagination')?.totalCount === 1) {
                        this.setActiveOrganisation(res.result()[0]);
                        return of(true);
                    }
                    if (orgID) {
                        const org = res.result().find(o => o.id === orgID);
                        if (org) {
                            this.setActiveOrganisation(org);
                            return of(true);
                        }
                    }
                    if (this.loadActiveOrganisation()) {
                        return of(true);
                    }
                    return of(false);
                })
            );
        }
        return of(true);
    }

    getOrganisationName(): string {
        return localStorage.getItem(ORGANISATION_NAME);
    }

    getInfoListOrganisation(): InfoList {
        return this.#infoListOrganisations.value;
    }

    setInfoListOrganisation(totalCount: number = 0, filter: string = ''): void {
        return this.#infoListOrganisations.next({ totalCount, filter });
    }

    removeActiveOrganisation(): void {
        localStorage.removeItem(ORGANISATION_ID);
        localStorage.removeItem(ORGANISATION_NAME);
        this.appState.setActiveOrganisation(null);
    }

    createOrganisation(organisation: Organisation): Observable<Organisation> {
        return this.jsonApiService.post(organisation);
    }

    updateOrganisation(organisation: Organisation): Observable<Organisation> {
        return this.jsonApiService.patch(organisation);
    }

    getContactsByKind(kinds: ContactKind[], page: number, limit = LIMIT, organisationId?: string): Observable<ListResponse<Contact>> {
        const query = new RequestOptions(page, limit, organisationId ? false : true);
        if (kinds?.length) {
            query.addFilter({ key: [FieldNameKind], value: kinds, type: Op.In });
        }
        if (organisationId) {
            query.addFilter({ key: [FieldNameOrganisation, FieldNameId], value: organisationId });
        }
        const queryOptions = this.jsonApiService.generateQuery(query);

        return this.jsonApiService.list(Contact, GenericApiSvcConfig.withSearchOpts(queryOptions));
    }

    createContact(contact: Contact, organisationId?: string): Observable<Contact> {
        this.jsonApiService.includeOrganisationRelationship(contact, organisationId);
        return this.jsonApiService.post(contact);
    }

    #getOrganisationTargets(): string[] {
        const currentRole = this.accountService.getRole();
        if (currentRole !== Role.SUPERADMIN) {
            return this.accountService.getAllTargets();
        }
        return undefined;
    }
}
