/* Enums */
export enum ChannelKind {
    WHATSAPP = 'WHATSAPP',
    SMS = 'SMS',
}

export enum ChannelProvider {
    STOUR_MARINE = 'STOUR_MARINE',
    TWILIO = 'TWILIO',
    UIB = 'UIB',
}
export class ChannelProviderByKind {
    static readonly [ChannelKind.SMS] = [ChannelProvider.STOUR_MARINE, ChannelProvider.TWILIO];
    static readonly [ChannelKind.WHATSAPP] = [ChannelProvider.UIB];
}

export class ChannelKindByProvider {
    static readonly [ChannelProvider.STOUR_MARINE] = [ChannelKind.SMS];
    static readonly [ChannelProvider.TWILIO] = [ChannelKind.SMS];
    static readonly [ChannelProvider.UIB] = [ChannelKind.WHATSAPP];
}

export class ChannelProviderName {
    static readonly [ChannelProvider.STOUR_MARINE] = 'Stour Marine';
    static readonly [ChannelProvider.TWILIO] = 'Twilio';
    static readonly [ChannelProvider.UIB] = 'UIB';
}

export class ChannelKindName {
    static readonly [ChannelKind.WHATSAPP] = 'WhatsApp';
    static readonly [ChannelKind.SMS] = 'SMS';
}
